@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  border-radius: 4px;
  box-shadow: 1px 1px 4px rgba(43, 43, 43, 0.15);
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom-color: $borderColor;
    border-radius: 4px 4px 0 0;
  }
  &-bordered {
    border-color: $borderColor;
  }
}

.trainee-list-card {
  box-shadow: none;
  padding-top: 16px;
}

.program-meta-card {
  height: 100%;

  .#{$prefix}-head-title {
    display: flex;
    align-items: center;

    .#{$antPrefix}icon {
      margin-right: 10px;
      color: $primaryColor;
    }
  }
}

.quiz-detail-wrapper {
  border: none;
  box-shadow: none;

  .#{$prefix}-head {
    padding: 0 20px;
  }

  > .#{$prefix}-body {
    padding: 10px 20px 20px;

    .#{$prefix}-head {
      background: rgba(173, 193, 225, 0.4);
      border-bottom-color: transparent;
    }

    .#{$prefix}-body {
      padding: 20px;

      .#{$antPrefix}-checkbox-inner {
        margin-right: 6px;
      }

      .#{$antPrefix}-radio-group .#{$antPrefix}-radio-wrapper {
        margin-bottom: 4px;
      }

      .#{$antPrefix}-table table {
        max-width: 100%;
      }

      .#{$antPrefix}-table .#{$antPrefix}-table-thead > tr > th {
        background: $componentBackground;
        border-color: $componentBackground;
        color: $textColor;
      }

      .#{$antPrefix}-table .#{$antPrefix}-table-thead > tr > th, .#{$antPrefix}-table .#{$antPrefix}-table-tbody > tr > td:not(:first-child) {
        text-align: center;

        .#{$antPrefix}-radio-wrapper {
          margin-right: 0;
        }
      }

      .#{$antPrefix}-table .#{$antPrefix}-table-tbody > tr:last-child > td {
        border-bottom: none;
      }
    }
  }
}