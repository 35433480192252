@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-tab-btn:focus, .#{$prefix}-tab-remove:focus, .#{$prefix}-tab-btn:active {
  color: $primaryColor;
}

.#{$prefix}-tab-remove:active, .#{$prefix}-tab.#{$prefix}-tab-active .#{$prefix}-tab-btn {
  color: $textColor;
}

.session-tabs > .#{$prefix}-nav {
  margin-left: 20px;

  .#{$prefix}-tab {
    padding: 12px 5px;
  }
}

.session-tabs .#{$prefix}-content {

  .#{$prefix}-tabpane {
    padding-bottom: 20px;

    > .#{$antPrefix}-divider-horizontal:not(:first-child), .#{$antPrefix}-list-items .#{$antPrefix}-divider-horizontal:not(:first-child) {
      margin-top: 28px;
    }
  }

  .#{$antPrefix}-card, .#{$antPrefix}-table-wrapper {
    margin: 0 20px;
  }

  .#{$antPrefix}-card {
    min-width: 300px;
  }

  .#{$antPrefix}-card-head {
    padding: 0 20px;
  }

  .#{$antPrefix}-card-body {
    .#{$antPrefix}-page-header {
      padding: 0 8px 4px 0;
    }
    .#{$antPrefix}-page-header:not(:first-child) {
      padding-top: 20px;
    }
    .#{$antPrefix}-page-header-heading-title {
      font-size: 16px;
    }
  }

  .#{$antPrefix}-collapse {
    border-radius: 4px;

    .#{$antPrefix}-collapse-content {
      border-radius: 0 0 4px 4px;
      background: #fff;
    }
  }

  .anticon-close {
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      color: $secondaryColor;
    }
  }

  .#{$antPrefix}-layout-content {
    padding: 8px 20px !important;
  }
}
