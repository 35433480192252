@import 'variables';

$prefix: $antPrefix + -table;

%boredStyles {
  &.#{$prefix}-bordered {
    table thead > tr:not(:last-child) > th {
      border-color: $borderColor;
    }
    thead > tr > th,
    tbody > tr > td,
    tfoot > tr > th,
    tfoot > tr > td {
      border-color: $borderColor;
    }
    .#{$prefix}-container {
      border-color: $borderColor;
    }
    .#{$prefix}-expanded-row-fixed::after {
      border-color: $borderColor;
    }
  }
}

.#{$prefix} {
  background: transparent;
  .row-danger,
  .row-warning,
  .row-success {
    & > td {
      position: relative;
      &:first-of-type::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 3px;
        left: 0;
        top: 0;
      }
    }
  }

  .row-warning {
    & > td {
      background: rgba(239, 133, 0, 0.15) !important;
      /* color: #faad14 !important; */
      &:first-of-type::before {
        background-color: #faad14;
      }
    }
  }

  .row-danger {
    & > td {
      background: $errorA08 !important;
      /* color: #ff4d4f !important; */
      &:first-of-type::before {
        background-color: $errorColor;
      }
    }
  }

  .row-success {
    & > td {
      background: rgba(76, 183, 91, 0.08) !important;
      /* color: #52c41a !important; */
      &:first-of-type::before {
        background-color: #52c41a;
      }
    }
  }

  .title-center {
    span {
      justify-content: center;
    }
  }

  .text,
  .title {
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
  }

  .check-in-field-title {
    text-align: center;
    .#{$prefix}-header-column {
      width: 10vw;
      white-space: normal;
    }
  }

  .tree-expand-placeholder {
    .#{$prefix}-row-expand-icon {
      margin-right: 0px;
      margin-left: 8px;
    }
  }

  .no-padding {
    padding: 0px;
  }

  .title-avatar-indent {
    .#{$prefix}-header-column {
      > div {
        text-indent: 63px;
      }
    }
  }

  .title-large-icon-indent {
    .#{$prefix}-header-column {
      > div {
        text-indent: 12px;
      }
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .small-head-padding & {
    .#{$prefix}-thead > tr > th {
      padding: 6px;
    }
  }

  .#{$prefix}-thead > tr > th,
  .#{$prefix}-tbody > tr > td {
    white-space: nowrap;
  }

  .#{$prefix}-row-level-1 {
    .#{$prefix}-row-expand-icon {
      display: none;
    }
    td {
      border-bottom-color: transparent;
    }
  }

  .row--nested-table {
    padding: 0px 50px 0px 0px;
    margin: -3px -16px -6px;
    table {
      background-color: transparent;
      thead {
        background-color: transparent;
        > tr > th {
          background-color: transparent;
        }
      }
      tbody {
        background-color: transparent;
        > tr > td {
          border: none;
        }
        .#{$prefix}-row:hover > td {
          background: transparent;
        }
      }
    }
  }
  .#{$prefix} {
    &-thead > tr > th {
      position: relative;
      color: #fff;
      background: #474747;
      border-color: #474747;
      .#{$prefix}-filter-column,
      .#{$prefix}-column-sorters {
        .#{$prefix}-filter-trigger-container .#{$antPrefix}-dropdown-trigger,
        .#{$prefix}-column-sorter, .#{$prefix}-column-sorter-full {
          color: #fff;
          background: #474747;
          &:hover {
            color: $primaryColor;
            background: #474747;
          }
        }
      }
      &.#{$prefix}-column-has-sorters:hover {
        background: #474747;
      }
    }
    &-tbody {
      & > tr:not(.#{$prefix}-expanded-row) > td {
        background: $componentBackground;
      }
      & > tr.#{$prefix}-expanded-row .#{$prefix}-thead tr th {
        background: rgb(79, 113, 168);
        border-color: rgb(79, 113, 168);
        color: #fff;

        .#{$prefix}-column-sorter {
          background: rgb(79, 113, 168);
          color: #fff;
        }
      }
      & > tr.#{$prefix}-expanded-row > td {
        padding: 32px 10px;
      }
      & > tr.#{$prefix}-expanded-row .#{$prefix}-tbody tr td {
        background: #fff;
      }
      & > tr {
        & > td {
          border-color: $borderColor;
          color: $textColor;
        }
        &.#{$prefix}-row-hover,
        &:hover {
          &:not(.#{$prefix}-expanded-row):not(.#{$prefix}-row-selected) {
            & > td {
              background: $primaryHover;
            }
          }
        }
      }
      tr.#{$prefix}-placeholder:hover > td.#{$prefix}-cell {
        background: $componentBackground;
      }
    }
    &-placeholder {
      background: $componentBackground;
      border: 1px solid #474747;
      .#{$antPrefix}-empty {
        color: $disabledColor;
        &-image {
          svg g g[fill-rule='nonzero'] {
            stroke: $borderColor !important;
            path {
              &:first-child {
                fill: $componentBackground !important;
              }
              &:last-child {
                fill: #474747 !important;
              }
            }
          }
          svg ellipse {
            fill: #474747 !important;
          }
        }
      }
    }
    &-header {
      background: transparent;
    }
  }
  .#{$prefix}-row.hide-expand-icon .#{$prefix}-row-expand-icon {
    cursor: default;
  }

  .#{$prefix}-row:not(.hide-expand-icon) .#{$prefix}-row-expand-icon {
    background-color: $bodyBackground;
    border-color: $bodyBackground;
  }

  tr.#{$prefix}-expanded-row,
  tr.#{$prefix}-expanded-row:hover {
    background: #fff;
    .row--nested-table table thead > tr > th {
      border-color: $borderColor;
    }
  }

  tr.#{$prefix}-row-selected {
    td {
      background: $bodyBackground;
    }
    &:hover {
      td {
        background: $contrastBackground;
      }
    }
  }

  &-filter-dropdown {
    background: $bodyBackground;
    .#{$antPrefix}-input {
      background: $contrastBackground;
    }
    &-btns {
      border-color: $primaryColor;
    }
  }

  @extend %boredStyles;
}

.#{$prefix}-filter-column {
  .#{$prefix}-filter-column-title {
    padding: 0 !important;
    flex: unset !important;
    //background-color: #5469d4 !important;

    .#{$prefix}-column-sorters-with-tooltip {
      width: unset !important;
    }
  }

  .#{$prefix}-filter-trigger-container {
    position: initial !important;
    left: 0;

    .#{$prefix}-filter-trigger .anticon {
      left: unset !important;
      margin-left: -5px;
      top: calc(50% + 1px) !important;
    }
    //right: unset !important;
  }
}

.#{$prefix}-column-sorter-up.active, .#{$prefix}-column-sorter-down.active {
  color: $primaryColor;
}

.#{$prefix} {
  border-radius: 4px;
}

.#{$prefix}-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

.#{$prefix}-container table > thead > tr:last-child th:last-child {
  border-top-right-radius: 4px;
}

.#{$prefix}-row-expand-icon:focus, .#{$prefix}-row-expand-icon:hover {
  color: $primaryColor;
}

.#{$prefix}.#{$prefix}-bordered > .#{$prefix}-container {
  border: none;
}

.ant-table-filter-column {
  .ant-table-filter-column-title {
    padding: 0 !important;
    flex: unset !important;
    //background-color: #5469d4 !important;

    .ant-table-column-sorters-with-tooltip {
      width: unset !important;
    }
  }

  .ant-table-filter-trigger-container {
    position: initial !important;
    left: 0;

    .ant-table-filter-trigger .anticon {
      left: unset !important;
      margin-left: -5px;
      top: calc(50% + 1px) !important;
    }
    //right: unset !important;
  }
}

.session-signings-table table {
  width: fit-content;
  max-width: 100%;
}

.session-signings-table {
  margin: 0 !important;
}

.session-signings-table .#{$prefix}.#{$prefix}-bordered > .#{$prefix}-container > .#{$prefix}-content > table > thead > tr > th, .session-signings-table .#{$prefix}.#{$prefix}-bordered > .#{$prefix}-container > .#{$prefix}-header > table > thead > tr > th, .session-signings-table .#{$prefix}.#{$prefix}-bordered > .#{$prefix}-container > .#{$prefix}-body > table > thead > tr > th {
  border-right: #474747;
  text-align: center;
}

.session-signings-table tbody > tr > td:not(:first-child) {
  text-align: center;

  .#{$antPrefix}-checkbox-wrapper {
    margin-left: 0;
  }
}

.session-signings-table tbody > tr > td:first-child {
  min-width: 122px;
  width: 122px;
}

.session-signings-table .#{$prefix}.#{$prefix}-bordered thead > tr > .#{$prefix}-cell-scrollbar {
  background: transparent;
  border-color: transparent;
}
